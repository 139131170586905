import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useContext, useMemo } from 'react'
import { FormProvider, useForm } from "react-hook-form"

import AssetDeleteButton from '../../components/asset/delete-button'
import AssetLinkedRecords from '../../components/asset/linked-records'
import AssetPurgeButton from '../../components/asset/purge-button'
import AutoSave from '../../components/autosave'
import { useTitle } from '../../components/browser'
import Errors from '../../components/errors'
import Fieldset from '../../components/form/fieldset'
import File from '../../components/form/file'
import Input from '../../components/form/input'
import Textarea from '../../components/form/textarea'
import Layout from '../../components/layout'
import LayoutCompass from '../../components/layout/compass'
import LayoutContent from '../../components/layout/content'
import NotificationContext from '../../components/notifications/context'
import SectionEditingStatus from '../../components/record/editing-status'
import RecordTimestamps from '../../components/record/timestamps'
import assetFragment from '../../fragments/media'
import { requireAuthenticatedEditor } from '../../services/authentication'

const GET_ASSET_QUERY = gql`
  query GetAsset($id: ID!) {
    asset(id: $id) {
      ${assetFragment}
    }
  }
`

const UPDATE_ASSET_MUTATION = gql`
  mutation UpdateSeriesMutation($id: ID!, $titleDe: String, $titleEn: String, $descriptionDe: String, $descriptionEn: String, $fileDe: String, $fileEn: String) {
    updateAsset(input: { id: $id, titleDe: $titleDe, titleEn: $titleEn, descriptionDe: $descriptionDe, descriptionEn: $descriptionEn, fileDe: $fileDe, fileEn: $fileEn }) {
      asset {
        ${assetFragment}
      }
    }
  }
`
export default function AssetForm({ id }) {
  requireAuthenticatedEditor()

  const { addNotification } = useContext(NotificationContext)
  const methods = useForm()
  const { loading, error, data } = useQuery(GET_ASSET_QUERY,
    {
      variables: { id },
      onCompleted: (data) => methods.reset(data.asset)
    }
  )
  const [mutate, { loading: mutating }] = useMutation(UPDATE_ASSET_MUTATION, {
    onError: (err) => {
      console.log(err)
      addNotification({ title: 'Error updating series', message: 'The asset could not be updated.', type: 'error' })
    }
  })

  const title = useMemo(() => `${data?.asset?.titleDe} - Media`, [data])
  useTitle(title)

  if (error) {
    console.error(error)
    return <div>There was an error loading the asset.</div>
  }

  const onSubmit = () => { }

  return (
    <Layout>
      <LayoutCompass
        label="asset"
        title={title}
        backTo='/media'
      />
      <LayoutContent loading={loading}>
        <FormProvider {...methods}>
          <div className="flex justify-between space-x-6">
            <div className="bg-white rounded-lg shadow flex-1 p-6">
              <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col space-y-8 flex-1">
                <Errors errors={methods.formState.errors} />
                <Fieldset>
                  <Input
                    label="Title - German"
                    name="titleDe"
                    register={methods.register}
                  />
                  <Input
                    label="Title- English"
                    name="titleEn"
                    register={methods.register}
                  />
                </Fieldset>
                <Fieldset>
                  <Textarea
                    label="Description - German"
                    name="descriptionDe"
                    control={methods.control}
                  />
                  <Textarea
                    label="Description - English"
                    name="descriptionEn"
                    control={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <File
                    label="File - German"
                    name="fileDe"
                    control={methods.control}
                  />
                  <File
                    label="File - English"
                    name="fileEn"
                    control={methods.control}
                  />
                </Fieldset>
              </form>
            </div>
            <AutoSave
              id={id}
              mutate={mutate}
              control={methods.control}
            />
            <div className="w-3/12 bg-white shadow rounded-lg sticky top-24 self-start space-y-4 p-6 text-gray-500">
              <SectionEditingStatus
                isDirty={methods.formState.isDirty}
                mutating={mutating}
              />
              <AssetLinkedRecords
                records={data?.asset?.linkedEntries}
              />
              <AssetPurgeButton
                assetId={data?.asset?.id}
              />
              <AssetDeleteButton
                assetId={data?.asset?.id}
                hasLinkedRecords={data?.asset?.linkedEntries.length > 0}
              />
              <RecordTimestamps
                record={data?.asset}
              />
            </div>
          </div>
        </FormProvider>
      </LayoutContent>
    </Layout>
  )
}
